import * as React from "react";
import {Button, Divider, List, ListItem, ListItemText, Paper, Stack, TextField} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faDog, faPaw, faQuestion, faEye} from "@fortawesome/free-solid-svg-icons";
import Api from "../Api/Api";

library.add(faDog, faPaw, faQuestion, faEye);

export class MainMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pwd: "",
            loading: false,
            finals: []
        };
        this.api = new Api();
    }

    componentDidMount() {
        this.getFinals();
    }

    getFinals() {
        this.setState({loading: true});
        this.api.getFinals("2022")
            .then((response) => {
                this.setState({loading: false, finals: response.data});
            })
            .catch(() => {
                this.setState({loading: false});
                alert("Kunde inte hämta finalerna, försök igen");
            })
    }

    render() {
        return (
            <Stack component="main" sx={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '100px',
                width: '100%',
                maxWidth: 600,
            }}>
                <nav aria-label="main">
                    <List sx={{bgcolor: '#202224'}} component={Paper}>
                        <ListItem disablePadding>
                            <Stack direction="row" sx={{margin: 'auto'}}>
                                <TextField
                                    sx={{marginRight: '16px', marginBottom: '20px'}}
                                    id="pwd"
                                    variant="standard"
                                    type="text"
                                    color="primary"
                                    label="Lösenord"
                                    autoComplete="off"
                                    value={this.state.pwd}
                                    onChange={(event) => {
                                        this.setState({pwd: event.target.value})
                                    }}
                                />
                                <Button sx={{marginBottom: '20px'}} variant="contained" onClick={() => {
                                    localStorage.setItem('be-pwd', this.state.pwd);
                                    this.setState({pwd: ""})
                                }}>Spara</Button>
                            </Stack>
                        </ListItem>
                        {/*{this.listItem('/editFinal?id=17&name=XS', '10px', 'XS startlista', 'dog', 1)}*/}
                        {/*{this.listItem('/editActiveFinal?id=17&name=XS', '10px', 'XS resultat', 'dog', 1)}*/}
                        {divider()}
                        {this.listItem('/editFinal?id=18&name=S/XS', '13px', 'S/XS startlista', 'dog', 2)}
                        {this.listItem('/editActiveFinal?id=18&name=S/XS', '13px', 'S/XS resultat', 'dog', 2)}
                        {divider()}
                        {this.listItem('/editFinal?id=19&name=M', '16px', 'M startlista', 'dog', 3)}
                        {this.listItem('/editActiveFinal?id=19&name=M', '16px', 'M resultat', 'dog', 3)}
                        {divider()}
                        {this.listItem('/editFinal?id=20&name=L', '19px', 'L startlista', 'dog', 4)}
                        {this.listItem('/editActiveFinal?id=20&name=L', '19px', 'L resultat', 'dog', 4)}
                        {divider()}
                        {this.listItem('/editFinal?id=21&name=XL', '22px', 'XL startlista', 'dog', 5)}
                        {this.listItem('/editActiveFinal?id=21&name=XL', '22px', 'XL resultat', 'dog', 5)}
                        {divider()}
                        {this.listItem('/editFinal?id=22&name=team S', '13px', 'Team S startlista', 'paw', 6)}
                        {this.listItem('/editActiveFinal?id=22&name=team s', '13px', 'Team S resultat', 'paw', 6)}
                        {divider()}
                        {this.listItem('/editFinal?id=23&name=team M', '16px', 'Team M startlista', 'paw', 7)}
                        {this.listItem('/editActiveFinal?id=23&name=team M', '16px', 'Team M resultat', 'paw', 7)}
                        {divider()}
                        {this.listItem('/editFinal?id=24&name=team L', '19px', 'Team L startlista', 'paw', 8)}
                        {this.listItem('/editActiveFinal?id=24&name=team L', '19px', 'Team L resultat', 'paw', 8)}
                        {divider()}
                        {this.listItem('/lazyDog', '21px', 'Lathund', 'question', 9999)}
                    </List>
                    <List sx={{bgcolor: '#202224', marginTop: "50px"}} component={Paper}>
                        {this.listItem('https://luleaopen.nordicbits.se', '21px', 'Besökssidan', 'eye', 9999, true)}
                    </List>
                </nav>
            </Stack>
        );
    }

    listItem(link, size, name, icon, id, blank) {
        let live = "";
        this.state.finals.forEach(final => {
            if (final.id === id && final.live) {
                live = <ListItemText sx={{color: "red"}} primary="LIVE"/>;
            }
        });
        return <ListItem disablePadding secondaryAction={
            live
        }>
            <ListItemButton onClick={() => {
                if (blank) {
                    setTimeout(() => {
                        window.open(link, '_blank');
                    }, 300);
                } else {
                    setTimeout(() => {
                        window.location.href = link;
                    }, 300);

                }
            }} sx={{color: "black"}}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={icon} fontSize={size}/>
                </ListItemIcon>
                <ListItemText sx={{color: "white"}} primary={name}/>
            </ListItemButton>
        </ListItem>
    }
}

const divider = function () {
    return <Divider component="li" sx={{
        bgcolor: "#955639",
        marginLeft: '16px',
        marginRight: '16px',
        marginBottom: '2px',
        marginTop: '2px'
    }}/>;
}


export default MainMenu;