import {createTheme} from "@mui/material";

export const luleaOpenTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#1d2329'
        },
        primary: {
            main: '#3f4242',
            dark: '#3f4242',
            light: '#3f4242',

        },
        alternateTextColor: {
            main: "#ff0000",
            dark: '#955639',
            light: '#955639',
            contrastText: '#955639'
        },
        secondary: {
            main: '#ffffff',
        },
        text: {
            primary: '#FAFAFA',
            secondary: "#FAFAFA"
        },
    },
    // components: {
    //     MuiCheckbox: {
    //         styleOverrides: {
    //             // Name of the slot
    //             root: {
    //                 '&$checked': {
    //                     color: '#FFFFFF',
    //                 },
    //             },
    //         },
    //     }
    // },
    li: {
        '&:hover': {
            background: "#f00",
        },
    },
});