import * as React from 'react';
import {
    Box,
    Grid, ListItem, Paper, Stack, Typography,
} from "@mui/material";
import {margin, styled} from '@mui/system';
import FlipMove from "react-flip-move";
import List from "@mui/material/List";
import {firstBy} from "thenby";
import Divider from "@mui/material/Divider";
import Api from "../Api/Api";
import logo from "../../images/logo.webp";


export class WatchRace extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dogs: [],
            live: false,
            name: ""
        };

        this.api = new Api();
    }

    componentDidMount() {
        let name = new URLSearchParams(window.location.search).get("name");
        this.setState({name: name})
        document.title = 'Luleå Open final ' + name;
        this.getFinal();
    }

    getFinal() {
        const i = new URLSearchParams(window.location.search).get("id");
        this.api.getFinal({id: i})
            .then((response) => {
                this.sortDogs(response.data.dogs);
                this.setState({live: response.data.live})
            })
            .catch((err) => console.log(err));
        setTimeout(function () {
            this.getFinal();
        }.bind(this), 5000);
    }

    render() {
        let hasDividedStartOrder = false;
        let hasDividedDisqualified = false;
        let live =
            <Typography sx={{margin: 'auto', color: 'red', height: '50px'}}
                        variant="h4">{this.state.live ? 'LIVE' : ''}</Typography>;
        let name =
            <Typography sx={{margin: 'auto', color: 'white', height: '50px'}}
                        variant="h4">{this.state.name.includes("Team") ? "" : "Final"} {this.state.name}</Typography>;
        let liveText = this.state.live ? <Typography
            sx={{
                padding: '16px',
                margin: 'auto',
                color: '#ffffff',
                fontSize: '12px',
                marginBottom: '20px',
                textAlign: 'center'
            }}>(The list
            is automatically updated, you don't need to refresh the page)</Typography> : "";
        return (
            <Stack
                component="main"
                sx={{marginTop: '10px', marginBottom: '30px'}}
            >
                <Box component="img" src={logo}
                     sx={{marginLeft: 'auto', marginRight: 'auto', marginBottom: '20px', maxWidth: '205px'}}/>
                {name}
                {live}
                {liveText}

                <List sx={{width: '100%', maxWidth: 800, bgcolor: '#202224', margin: 'auto'}} component={Paper}>
                    <FlipMove>
                    {this.state.dogs.map((dog, index) => {
                        if (!dog.running) {
                            return(<ListItem sx={{height: '0px', padding: '0px'}}
                                key={dog.id}></ListItem>);
                        }
                        let currentRunner = dog.running ? groupText('Current runner') : "";
                        let currentRunnerDivider = dog.running ? inlineDivider() : "";
                        let runningIndicator = dog.running ? runningCircle() : "";
                        return (
                            <ListItem
                                key={dog.id}>
                                <Stack sx={{width: '100%', marginLeft: dog.running ? '0px' : '0px'}}>
                                    {currentRunner}
                                    {currentRunnerDivider}
                                    <Grid container spacing={2} sx={{
                                        bgcolor: dog.running ? '#292929' : '',
                                        paddingBottom: dog.running ? '20px' : '0px',
                                        marginLeft: dog.running ? '0px' : '',
                                        width: 'auto'
                                    }}>
                                        <Grid item xs={1} md={2}>
                                            {runningIndicator}
                                        </Grid>
                                        <Grid item xs={4} md={3}>
                                            <span>{dog.owner}</span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span>{dog.name}</span>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <span>{dog.faults === -1 ? "" : dog.faults}</span>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <span>{dog.time === -1 ? "" : dog.time}</span>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </ListItem>
                        );
                    })}
                    <ListItem key={'header'}>
                        <Grid container spacing={2}>
                            <Grid item xs={1} md={2}>
                                <HeaderText>Pl</HeaderText>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <HeaderText>{this.state.name.includes("Team", 0) ? "Team" : "Handler"}</HeaderText>
                            </Grid>
                            <Grid item xs={3}>
                                <HeaderText>{this.state.name.includes("Team", 0) ? "" : "Dog"}</HeaderText>
                            </Grid>
                            <Grid item xs={2}>
                                <HeaderText>Faults</HeaderText>
                            </Grid>
                            <Grid item xs={2}>
                                <HeaderText>Time</HeaderText>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Divider component="li" sx={{bgcolor: "#955639", marginLeft: '16px', marginRight: '16px'}}/>

                        {this.state.dogs.map((dog, index) => {
                            if (dog.running) {
                                return(<ListItem
                                    key={dog.id}></ListItem>);
                            }
                            let anyRunning = this.state.dogs.filter(d => d.running).length > 0;
                            let placeText = dog.time !== -1 ? index + (anyRunning ? 0 : 1) : dog.startOrder;
                            placeText = dog.running || dog.disqualified ? '' : placeText;
                            let place = <span sx={{marginTop: '6px !important'}}> {placeText}</span>;
                            let notStarted;
                            let disqualified;
                            let notStartedLine;
                            let disqualifiedLine;
                            if (!hasDividedStartOrder && dog.time === -1 && !dog.running && !dog.disqualified) {
                                hasDividedStartOrder = true;
                                notStarted = groupText('Start order');
                                notStartedLine = inlineDivider();
                            } else {
                                notStarted = "";
                                notStartedLine = "";
                            }

                            if (!hasDividedDisqualified && dog.disqualified) {
                                hasDividedDisqualified = true;
                                disqualified = groupText('Disqualified');
                                disqualifiedLine = inlineDivider();
                            } else {
                                disqualified = "";
                                disqualifiedLine = "";
                            }
                            let currentRunner = dog.running ? groupText('Current runner') : "";
                            let currentRunnerDivider = dog.running ? inlineDivider() : "";
                            let runningIndicator = dog.running ? runningCircle() : "";
                            return (
                                <ListItem
                                    key={dog.id}>
                                    <Stack sx={{width: '100%', marginLeft: dog.running ? '0px' : '0px'}}>
                                        {currentRunner}
                                        {currentRunnerDivider}
                                        {disqualified}
                                        {disqualifiedLine}
                                        {notStarted}
                                        {notStartedLine}
                                        <Grid container spacing={2} sx={{
                                            bgcolor: dog.running ? '#292929' : '',
                                            paddingBottom: dog.running ? '20px' : '0px',
                                            marginLeft: dog.running ? '0px' : '',
                                            width: 'auto'
                                        }}>
                                            <Grid item xs={1} md={2}>
                                                {place}
                                                {runningIndicator}
                                            </Grid>
                                            <Grid item xs={4} md={3}>
                                                <span>{dog.owner}</span>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <span>{dog.name}</span>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <span>{dog.faults === -1 ? "" : dog.faults}</span>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <span>{dog.time === -1 ? "" : dog.time}</span>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </ListItem>
                            );
                        })}
                    </FlipMove>
                </List>
            </Stack>
        );
    }

    sortDogs(dogs) {
        dogs.sort(firstBy((a, b) => b.running - a.running)
            .thenBy((a, b) => {
                if (a.faults === -1 && b.faults === -1) return 0;
                if (b.faults === -1) return -1;
                if (a.faults === -1) return 1;
                return a.faults - b.faults;
            })
            .thenBy((a, b) => a.time - b.time)
            .thenBy((a, b) => Number(b.disqualified) - Number(a.disqualified)));
        this.setState({dogs: dogs});
    }
}

const HeaderText = styled('strong')(({theme}) => ({
    'font-size': '14px',
}));

const inlineDivider = function () {
    return <Divider sx={{bgcolor: "#955639", marginTop: '10px', marginBottom: '16px'}}/>;
}

const groupText = function (name) {
    return <HeaderText sx={{justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px'}}>
        {name}
    </HeaderText>;
}

const runningCircle = function () {
    return <div className={'fadeInOut'}/>;
}

export default WatchRace;