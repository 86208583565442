import * as axios from "axios";

export default class Api {
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = "https://nordicbits.se";
    }

    init = () => {
        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
        });

        return this.client;
    };

    getFinal = (params) => {
        return this.init().get("/Final.php?", { params: params });
    };

    getFinals = (year) => {
        return this.init().get("/Finals.php?year="+year);
    };

    addDog = (data) => {
        data.pw = localStorage.getItem('be-pwd');
        return this.init().post("/AddDog.php", data);
    };

    deleteDog = (data) => {
        data.pw = localStorage.getItem('be-pwd');
        return this.init().post("/DeleteDog.php", data);
    };

    updateDog = (data) => {
        data.pw = localStorage.getItem('be-pwd');
        return this.init().post("/UpdateDog.php", data);
    };

    updateFinal = (data) => {
        data.pw = localStorage.getItem('be-pwd');
        return this.init().post("/UpdateFinal.php", data);
    }
}