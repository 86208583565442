import * as React from 'react';
import Api from "../Api/Api";
import {Checkbox, Grid, ListItem, Paper, Stack, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import FlipMove from "react-flip-move";
import Box from "@mui/material/Box";
import EditActiveRace, {HeaderText} from "./EditActiveRace";
import {firstBy} from "thenby";

export class EditRace extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            finalId: 0,
            dogs: [],
            savingAddDog: false,
            addOwner: "",
            addDogName: "",
            addStartOrder: "",
            updateDogId: 0,
            deleteDogId: 0
        };

        this.api = new Api();
    }

    componentDidMount() {
        this.getFinal();
    }

    getFinal() {
        const i = new URLSearchParams(window.location.search).get("id");
        const n = new URLSearchParams(window.location.search).get("name");
        this.setState({name: n, finalId: i})
        this.api.getFinal({id: i})
            .then((response) => {
                this.setState({dogs: response.data.dogs, id: i});
            })
            .catch(() => alert("Kunde inte hämta finalen, försök igen"));
    }

    changeDogValue(dog, action) {
        action();
        const index = this.state.dogs.findIndex(d => d.key === dog.id);
        const dogsCopy = [...this.state.dogs];
        dogsCopy[index] = dog;
        this.setState({dogs: dogsCopy});
    }

    addDog() {
        this.setState({savingAddDog: true});
        this.api.addDog({
            finalId: this.state.finalId,
            owner: this.state.addOwner,
            name: this.state.addDogName,
            startOrder: this.state.addStartOrder
        })
            .then((response) => {
                this.setState({savingAddDog: false});
                if (!response.data.success) {
                    alert(response.data.message);
                    return;
                }
                this.setState({addOwner: "", addDogName: "", addStartOrder: ""});
                this.getFinal();
            })
            .catch((err) => {
                this.setState({saving: false});
                alert("Något gick snett " + err);
            })
    }

    updateDog(dog) {
        this.setState({updateDogId: dog.id});
        this.api.updateDog(dog)
            .then((response) => {
                this.setState({updateDogId: 0});
                if (!response.data.success) {
                    alert(response.data.message);
                    return;
                }
                this.getFinal();
            })
            .catch((err) => {
                this.setState({saving: false});
                alert("Något gick snett " + err);
            })
    }

    deleteDog(dog) {
        this.setState({deleteDogId: dog.id});
        this.api.deleteDog(dog)
            .then((response) => {
                this.setState({savingAddDog: false});
                if (!response.data.success) {
                    alert(response.data.message);
                    return;
                }
                this.setState({addOwner: "", addDogName: "", addStartOrder: ""});
                this.getFinal();
            })
            .catch((err) => {
                this.setState({saving: false});
                alert("Något gick snett " + err);
            })
    }

    render() {
        return (
            <Stack
                component="main"
                sx={{marginTop: '30px', marginBottom: '60px', marginLeft: 'auto', marginRight: 'auto'}}>
                <Typography sx={{margin: 'auto', color: '#ffffff'}}
                            variant="h4">Redigera {this.state.name} startlista</Typography>
                <List sx={{
                    width: '100%',
                    maxWidth: 1200,
                    bgcolor: '#202224',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '30px'
                }} component={Paper}>
                    <ListItem key={'addNewDog'}>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <TextField
                                    variant="standard"
                                    type="number"
                                    color="primary"
                                    label="Startnummer"
                                    autoComplete="off"
                                    value={this.state.addStartOrder}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        if (isNaN(+value)) {
                                            return;
                                        }
                                        this.setState({addStartOrder: value});
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    variant="standard"
                                    type="text"
                                    color="primary"
                                    label="Förare"
                                    autoComplete="off"
                                    value={this.state.addOwner}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        this.setState({addOwner: value});
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    variant="standard"
                                    type="text"
                                    color="primary"
                                    label="Hund"
                                    autoComplete="off"
                                    value={this.state.addDogName}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        this.setState({addDogName: value});
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}>
                                <LoadingButton sx={{width: '100%', height: '100%'}} onClick={this.addDog.bind(this)}
                                               loading={this.state.savingAddDog}
                                               variant="contained">Lägg till</LoadingButton>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Divider component="li" sx={{bgcolor: "#955639", margin: '16px'}}/>
                    <ListItem key={'header'}>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <HeaderText>Startnummer</HeaderText>
                            </Grid>
                            <Grid item xs={3}>
                                <HeaderText>Förare</HeaderText>
                            </Grid>
                            <Grid item xs={3}>
                                <HeaderText>Hund</HeaderText>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <FlipMove>
                        {this.state.dogs.map((dog, index) => {
                            return (
                                <ListItem
                                    key={dog.id}>
                                    <Stack sx={{
                                        width: '100%', "&:hover": {
                                            backgroundColor: '#29292b'
                                        }
                                    }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={2}>
                                                <TextField
                                                    variant="standard"
                                                    type="number"
                                                    color="primary"
                                                    autoComplete="off"
                                                    value={dog.startOrder}
                                                    onChange={(event) => {
                                                        const value = event.target.value;
                                                        if (isNaN(+value)) {
                                                            return;
                                                        }
                                                        this.changeDogValue(dog, () => {
                                                            dog.startOrder = event.target.value;
                                                        });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    variant="standard"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={dog.owner}
                                                    onChange={(event) => {
                                                        this.changeDogValue(dog, () => {
                                                            dog.owner = event.target.value;
                                                        });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    variant="standard"
                                                    type="text"
                                                    color="primary"
                                                    autoComplete="off"
                                                    value={dog.name}
                                                    onChange={(event) => {
                                                        this.changeDogValue(dog, () => {
                                                            dog.name = event.target.value;
                                                        });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <LoadingButton sx={{marginBottom: '26px'}}
                                                               onClick={() => {
                                                                   this.deleteDog(dog);
                                                               }}
                                                               loading={this.state.deleteDogId === dog.id}
                                                               color="error"
                                                               variant="contained">Radera</LoadingButton>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <LoadingButton sx={{marginBottom: '26px'}}
                                                               onClick={() => {
                                                                   this.updateDog(dog);
                                                               }}
                                                               loading={this.state.updateDogId === dog.id}
                                                               color="primary"
                                                               variant="contained">Uppdatera</LoadingButton>
                                            </Grid>
                                        </Grid>
                                        {/*<Divider component="li" sx={{bgcolor: "#3d3d3d"}}/>*/}
                                    </Stack>
                                </ListItem>
                            );
                        })}
                    </FlipMove>
                </List>
            </Stack>
        );
    }
}

export default EditRace;