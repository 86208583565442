import * as React from 'react';
import {
    Button, Checkbox,
    Grid, ListItem, Paper, Stack, TextField, Typography,
} from "@mui/material";
import {styled} from '@mui/system';
import FlipMove from "react-flip-move";
import List from "@mui/material/List";
import {firstBy} from "thenby";
import Divider from "@mui/material/Divider";
import Api from "../Api/Api";
import {LoadingButton} from "@mui/lab";


export class EditActiveRace extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            dogs: [],
            live: false,
            saving: false,
            savingLive: false,
            changed: false,
            id: 0
        };

        this.api = new Api();
    }

    componentDidMount() {
        this.getFinal();
    }

    getFinal() {
        const i = new URLSearchParams(window.location.search).get("id");
        const n = new URLSearchParams(window.location.search).get("name");
        this.setState({name: n})
        this.api.getFinal({id: i})
            .then((response) => {
                this.setState({live: response.data.live, dogs: response.data.dogs, id: i}, function () {
                    this.sortDogs();
                });
            })
            .catch(() => alert("Kunde inte hämta finalen, försök igen"));
    }

    save() {
        console.log("SAVE");
        this.setState({saving: true, changed: false});
        const dogsCopy = [...this.state.dogs];
        dogsCopy.forEach((dog) => {
            console.log(dog);
             if (!dog.disqualified && !dog.running && dog.time !== -1 && dog.faults === -1) {
                 dog.faults = 0;
             }
        });
        this.setState({dogs: dogsCopy});
        this.api.updateFinal({dogs: [...this.state.dogs]})
            .then((response) => {
                this.setState({saving: false});
                if (!response.data.success) {
                    alert(response.data.message);
                }
            })
            .catch((err) => {
                this.setState({saving: false});
                alert("Något gick snett " + err);
            })
    }

    saveLive() {
        this.setState({savingLive: true});
        const isLive = !this.state.live;
        this.api.updateFinal({id: this.state.id, live: isLive})
            .then((response) => {
                this.setState({saving: false});
                if (!response.data.success) {
                    alert(response.data.message);
                } else {
                    this.setState({savingLive: false, live: isLive});
                }
            })
            .catch((err) => {
                this.setState({saving: false});
                alert("Något gick snett " + err);
            })
    }

    changeDogValue(dog, action) {
        action();
        const index = this.state.dogs.findIndex(d => d.key === dog.id);
        const dogsCopy = [...this.state.dogs];
        dogsCopy[index] = dog;
        this.setState({dogs: dogsCopy, changed: true});
    }

    changeRunning(dog, running) {
        const dogsCopy = [...this.state.dogs];
        if (running) {
            dogsCopy.forEach((dog) => {
                dog.running = false;
            });
        }
        dog.running = running;
        const index = this.state.dogs.findIndex(d => d.key === dog.id);
        dogsCopy[index] = dog;
        this.setState({dogs: dogsCopy}, () => {
            this.sortDogs();
        });
        this.save();
    }

    sortDogs() {
        const dogsCopy = [...this.state.dogs];
        dogsCopy.sort(firstBy((a, b) => {
            if (a.faults === -1 && b.faults === -1) return 0;
            if (b.faults === -1) return -1;
            if (a.faults === -1) return 1;
            return a.faults - b.faults;
        }).thenBy((a, b) => a.time - b.time)
            .thenBy((a, b) => Number(b.disqualified) - Number(a.disqualified))
            .thenBy((a, b) => Number(b.running) - Number(a.running)));

        this.setState({dogs: dogsCopy});
    }

    render() {
        let hasDividedStartOrder = false;
        let hasDividedDisqualified = false;
        let live = this.state.live ?
            <Typography sx={{margin: 'auto', color: '#ffffff'}} variant="h4">LIVE</Typography> : '';
        let liveBtnText = this.state.live ? "Avaktivera live" : "Aktivera live";
        return (
            <Stack
                component="main"
                sx={{marginTop: '30px', marginBottom: '60px'}}>
                <Typography sx={{marginLeft: 'auto', marginRight: 'auto', marginBottom: '20px', color: '#ffffff'}} variant="h4">Redigera {this.state.name} resultat</Typography>
                {live}
                {/*onClick={this.addDog.bind(this)}*/}
                <LoadingButton onClick={this.saveLive.bind(this)} loading={this.state.savingLive} variant="contained"
                               sx={{margin: 'auto'}}>{liveBtnText}</LoadingButton>
                <List sx={{
                    width: '100%',
                    maxWidth: 1200,
                    bgcolor: '#202224',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '30px'
                }} component={Paper}>
                    <ListItem key={'actions'}>
                        <Stack>
                            <LoadingButton disabled={!this.state.changed} onClick={this.save.bind(this)} loading={this.state.saving}
                                           variant="contained">SPARA</LoadingButton>
                        </Stack>
                    </ListItem>
                    <ListItem key={'header'}>
                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                                <HeaderText>Diskad</HeaderText>
                            </Grid>
                            <Grid item xs={1}>
                                <HeaderText>Pl</HeaderText>
                            </Grid>
                            <Grid item xs={3}>
                                <HeaderText>Förare</HeaderText>
                            </Grid>
                            <Grid item xs={3}>
                                <HeaderText>Hund</HeaderText>
                            </Grid>
                            <Grid item xs={2}>
                                <HeaderText>Antal fel</HeaderText>
                            </Grid>
                            <Grid item xs={1}>
                                <HeaderText>Tid</HeaderText>
                            </Grid>
                            <Grid item xs={1}>
                                <HeaderText>Springer</HeaderText>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Divider component="li" sx={{bgcolor: "#955639", marginLeft: '16px', marginRight: '16px'}}/>
                    <FlipMove>
                        {this.state.dogs.map((dog, index) => {
                            let place = !dog.running && dog.time !== -1 ? <span>{index + 1}</span> : "";
                            let notStarted;
                            let disqualified;
                            let notStartedLine;
                            let disqualifiedLine;
                            if (!hasDividedStartOrder && dog.time === -1 && !dog.running && !dog.disqualified) {
                                hasDividedStartOrder = true;
                                notStarted = groupText('Startordning');
                                notStartedLine = inlineDivider();
                            } else {
                                notStarted = "";
                                notStartedLine = "";
                            }

                            if (!hasDividedDisqualified && dog.disqualified) {
                                hasDividedDisqualified = true;
                                disqualified = groupText('Diskade');
                                disqualifiedLine = inlineDivider();
                            } else {
                                disqualified = "";
                                disqualifiedLine = "";
                            }
                            let currentRunner = dog.running ? groupText('Nuvarande tävlande') : "";
                            let currentRunnerDivider = dog.running ? inlineDivider() : "";
                            let runningIndicator = dog.running ? runningCircle() : "";
                            let faultsTextField = !dog.running && (dog.disqualified || dog.faults === -1) ? "" : this.faultsTF(dog);
                            let timeTextField = !dog.running && (dog.disqualified || dog.faults === -1) ? "" : this.timeTF(dog);
                            return (
                                <ListItem
                                    key={dog.id}>
                                    <Stack sx={{width: '100%'}}>
                                        {currentRunner}
                                        {currentRunnerDivider}
                                        {disqualified}
                                        {disqualifiedLine}
                                        {notStarted}
                                        {notStartedLine}
                                        <Grid container spacing={2} sx={{
                                            bgcolor: dog.running ? '#292929' : '',
                                            paddingBottom: dog.running ? '20px' : '0px'
                                        }}>
                                            <Grid item xs={1} sx={{marginTop: '-6px'}}>
                                                <Checkbox
                                                    checked={dog.disqualified}
                                                    onChange={(event) => {
                                                        this.changeDogValue(dog, () => {
                                                            dog.disqualified = event.target.checked;
                                                            this.changeRunning(dog, false);
                                                        });
                                                        this.sortDogs();
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={1} sx={{marginTop: '6px'}}>
                                                {place}
                                                {runningIndicator}
                                            </Grid>
                                            <Grid item xs={3}>
                                                <span>{dog.owner}</span>
                                            </Grid>
                                            <Grid item xs={3}
                                                  sx={{marginTop: '6px'}}>
                                                <span>{dog.name}</span>
                                            </Grid>
                                            <Grid item xs={2}>
                                                {faultsTextField}
                                            </Grid>
                                            <Grid item xs={1}>
                                                {timeTextField}
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Checkbox
                                                    checked={dog.running}
                                                    onChange={(event) => {
                                                        this.changeRunning(dog, event.target.checked);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </ListItem>
                            );
                        })}
                    </FlipMove>
                </List>
            </Stack>
        );
    }

    faultsTF(dog) {
        return <TextField
            variant="standard"
            type="number"
            color="primary"
            onChange={(event) => {
                const value = event.target.value;
                if (isNaN(+value)) {
                    return;
                }
                this.changeDogValue(dog, () => {
                    dog.faults = parseInt(value);
                });
            }}
            value={dog.faults > -1 ? dog.faults : ''}
        />;
    }

    timeTF(dog) {
        return <TextField
            variant="standard"
            type="number"
            onChange={(event) => {
                const value = event.target.value;
                if (isNaN(+value)) {
                    return;
                }
                this.changeDogValue(dog, () => {
                    dog.time = parseFloat(value);
                });
            }}
            value={dog.time > -1 ? dog.time : ''}
        />;
    }
}

export const HeaderText = styled('strong')(({theme}) => ({
    'font-size': '14px',
}));

const inlineDivider = function () {
    return <Divider sx={{bgcolor: "#955639", marginTop: '10px', marginBottom: '16px'}}/>;
}

const groupText = function (name) {
    return <HeaderText sx={{justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px'}}>
        {name}
    </HeaderText>;
}

const runningCircle = function () {
    return <div className={'fadeInOut'}/>;
}

export default EditActiveRace;