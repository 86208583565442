import * as React from 'react';
import {List, ListItem, Typography} from "@mui/material";

export class LazyDog extends React.Component {

    render() {
        return <List>
            <ListItem>
                <Typography sx={{color: '#ffffff'}} variant={"h1"}>Lathund</Typography>
            </ListItem>
            <ListItem>
                <Typography sx={{color: '#ffffff'}} variant={"h4"}>Lösenord:</Typography>
            </ListItem>
            <ListItem>
                <Typography sx={{color: '#ffffff', marginLeft: '20px'}}>Skriv in lösenordet och tryck på spara, det
                    andvänds sen varje gång ni trycker på spara i andra delar av systemet (hann inte göra en mer elegant
                    lösning)</Typography>
            </ListItem>
            <ListItem>
                <Typography sx={{color: '#ffffff'}} variant={"h4"}>Redigera startlista:</Typography>
            </ListItem>
            <ListItem>
                <Typography sx={{color: '#ffffff', marginLeft: '20px'}}>Kom ihåg att skriva in nationalitet först i
                    namnet genom en emoji.</Typography>
            </ListItem>
            <ListItem>
                <Typography sx={{color: '#ffffff', marginLeft: '40px'}}>ctrl + cmd + space för </Typography>
            </ListItem>
            <ListItem>
                <Typography sx={{color: '#ffffff', marginLeft: '40px'}}>windowstangenten + . för ⊞</Typography>
            </ListItem>
            <ListItem>
                <Typography sx={{color: '#ffffff', marginLeft: '20px'}}>OBS! Trycker du på raderaknappen så raderas
                    deltagaren direkt (hade inte tid att implementera en bekräftelse)</Typography>
            </ListItem>
            <ListItem>
                <Typography sx={{color: '#ffffff', marginLeft: '20px'}}>Ändrar du startnummer, namn, eller hunden namn
                    så måste du trycka uppdatera på den raden. Det går inte att ändra flera och sen trycka
                    uppdatera.</Typography>
            </ListItem>
            <ListItem>
                <Typography sx={{color: '#ffffff', marginLeft: '20px'}}>Ändrar du startnummer på någon så måste du ändra
                    startnummer för alla de andra så att det bli rätt. Likaså om du raderar en så kommer inte
                    startnummer ändras för de andra.</Typography>
            </ListItem>
            <ListItem>
                <Typography sx={{color: '#ffffff', marginTop: '50px'}} variant={"h4"}>Redigera resultat:</Typography>
            </ListItem>
            <ListItem>
                <Typography sx={{color: '#ffffff', marginLeft: '20px'}}>Om du aktiverar en final som live, och har en
                    annan som står som live så måste du manuellt avaktivera den andra, annars står båda som
                    live.</Typography>
            </ListItem>
            <ListItem>
                <Typography sx={{color: '#ffffff', marginLeft: '20px'}}>Du kan göra flera ändringar, men sen måste du
                    alltid trycka spara för att ändringen ska gå igenom.</Typography>
            </ListItem>
            <ListItem>
                <Typography sx={{color: '#ffffff', marginLeft: '20px'}}>Om startordningen ändras så måste det helst fixas under redigera startlista, för det kommer se konstigt ut grafiskt annars.</Typography>
            </ListItem>
        </List>
    }
}

export default LazyDog;