import * as React from "react";
import {Box, Divider, List, ListItem, ListItemText, Paper, Stack, Typography} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faDog, faPaw, faQuestion} from "@fortawesome/free-solid-svg-icons";
import Api from "../Api/Api";
import logo from "../../images/logo.webp";

library.add(faDog, faPaw, faQuestion);

export class ListFinals extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            finals: []
        };
        this.api = new Api();
    }

    componentDidMount() {
        this.getFinals();
        document.title = 'Luleå Open finals';
    }

    getFinals() {
        this.setState({loading: true});
        this.api.getFinals("2024")
            .then((response) => {
                this.setState({loading: false, finals: response.data});
            })
            .catch(() => {
                this.setState({loading: false});
                alert("Kunde inte hämta finalerna, försök igen");
            })
    }

    render() {
        return (
            <Stack component="main" sx={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '10px',
                marginBottom: '30px',
                width: '100%',
                maxWidth: 600,
            }}>
                <Box component="img" src={logo} sx={{margin: 'auto', maxWidth: '205px', objectFit: 'fill'}} />
                <Typography sx={{marginLeft: 'auto', marginRight: 'auto', marginBottom: '20px', marginTop: "20px", color: '#ffffff'}} variant="h4">Finals 2024</Typography>
                <nav aria-label="main">
                    <List sx={{bgcolor: '#202224'}} component={Paper}>
                        {/*{this.listItem('/final?id=9&name=extra small', '10px', 'Extra small', 'dog', 9)}*/}
                        {this.listItem('/final?id=18&name=small/extra small', '13px', 'Small/Extra small', 'dog', 18)}
                        {this.listItem('/final?id=19&name=medium', '16px', 'Medium', 'dog', 19)}
                        {this.listItem('/final?id=20&name=large', '19px', 'Large', 'dog', 20)}
                        {this.listItem('/final?id=21&name=extra large', '22px', 'Extra large', 'dog', 21)}
                        {divider()}
                        {this.listItem('/final?id=22&name=Team small', '13px', 'Team small', 'paw', 22)}
                        {this.listItem('/final?id=23&name=Team medium', '16px', 'Team medium', 'paw', 23)}
                        {this.listItem('/final?id=24&name=Team large', '19px', 'Team large', 'paw', 24)}
                    </List>
                </nav>
            </Stack>
        );
    }

    listItem(link, size, name, icon, id) {
        let live = "";
        this.state.finals.forEach(final => {
            if (final.id === id && final.live) {
                live = <ListItemText sx={{color: "red"}} primary="LIVE"/>;
            }
        });
        return <ListItem disablePadding secondaryAction={
            live
        }>
            <ListItemButton onClick={() => {
                setTimeout(() => {
                    window.location.href = link;
                }, 300);
            }} sx={{color: "black"}}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={icon} fontSize={size}/>
                </ListItemIcon>
                <ListItemText sx={{color: "white"}} primary={name}/>
            </ListItemButton>
        </ListItem>
    }
}

const divider = function () {
    return <Divider component="li" sx={{
        bgcolor: "#955639",
        marginLeft: '16px',
        marginRight: '16px',
        marginBottom: '16px',
        marginTop: '16px'
    }}/>;
}


export default ListFinals;